const internal = {
	blog: "/blog",
	home: "/",
	contact: "/#contact",
} as const

const ourLinks = {} as const

const ourExternalLinks = {
	helpCenter: "https://help.lang.ai/",
	whatsnew: "https://roadmap.lang.ai/changelog",
	privacy:
		"https://langai.notion.site/Lang-ai-Privacy-Policy-103072d0ecd2808b921dd23809e216f7?pvs=74",
	terms: "TODO",
	cookiePolicy:
		"https://langai.notion.site/Lang-ai-Cookies-Policy-104072d0ecd280c7b99ed2f41d8d1f13",
	security: "https://trust.lang.ai/",
	linkedin: "https://www.linkedin.com/company/lang-ai/",
	snowflakeMarketplace:
		"https://app.snowflake.com/marketplace/listing/GZTSZ1TJ3IU/lang-ai-snowflake-ai-agents",
}

const thirdPartyLinks = {} as const

const links = {
	...internal,
	...ourLinks,
	...ourExternalLinks,
	...thirdPartyLinks,
} as const

export default links
